import React from 'react';
import { useAppDispatch } from '@src/hooks/useAppDispatch';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { reset, selectPayment, setPaymentDetails, setProcessStep } from '@src/store/PaymentSlice';
import { Modal } from 'react-bootstrap';
import { ProcessPayment } from '../Common/ProcessPayment';
import { ConfirmCheckAmount } from './ConfirmCheckAmount';
import { CheckPaymentDescription } from './CheckPaymentDescription';
import { ScanCheck } from '../Common/ScanCheck';

/**
 * ProcessCheckModal is a modal wizard for processing check payments.
 * This component provides the modal shell, presents the modal content for
 *   each wizard step, and orchestrates control flow for the steps.
 */
export const ProcessCheckModal = ({ show }: { show: boolean }) => {
    const dispatch = useAppDispatch();
    const { step } = useAppSelector(selectPayment);

    return (
        <Modal centered size="sm" show={show}>
            {show && step === 'Start' && (
                // Confirm the check amount and number
                <ConfirmCheckAmount
                    onContinue={checkNumber => {
                        dispatch(setPaymentDetails({ checkNumber }));
                        dispatch(setProcessStep('CheckScan'));
                    }}
                    onCancel={() => dispatch(reset())}
                />
            )}
            {show && step === 'CheckScan' && (
                // Prompt the associate to scan the check
                <ScanCheck onContinue={() => dispatch(setProcessStep('Finish'))} onCancel={() => dispatch(reset())}>
                    <CheckPaymentDescription />
                </ScanCheck>
            )}
            {show && step === 'Finish' && (
                // Submit the payment for processing
                <ProcessPayment onComplete={() => dispatch(reset())} onCancel={() => dispatch(reset())}>
                    <CheckPaymentDescription />
                </ProcessPayment>
            )}
        </Modal>
    );
};
