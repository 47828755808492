import React from 'react';
import { useAppDispatch } from '@src/hooks/useAppDispatch';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { reset, selectPayment, setPaymentDetails, setProcessStep } from '@src/store/PaymentSlice';
import { Modal } from 'react-bootstrap';
import { ConfirmCashAmount } from './ConfirmCashAmount';
import { TenderCashPayment } from './TenderCashPayment';
import { ProcessPayment } from '../Common/ProcessPayment';
import { CashPaymentDescription } from './CashPaymentDescription';

/**
 * ProcessCashModal is a modal wizard for processing cash payments.
 * This component provides the modal shell, presents the modal content for
 *   each wizard step, and orchestrates control flow for the steps.
 */
export const ProcessCashModal = ({ show }: { show: boolean }) => {
    const dispatch = useAppDispatch();
    const { mode, step } = useAppSelector(selectPayment);

    return (
        <Modal centered size="sm" show={show}>
            {mode == 'Process' && step === 'Start' && (
                // Confirm the amount of cash recieved by the customer
                <ConfirmCashAmount
                    OnContinue={amt => {
                        dispatch(setPaymentDetails({ cashCollected: amt }));
                        dispatch(setProcessStep('Tender'));
                    }}
                    onCancel={() => dispatch(reset())}
                />
            )}
            {((mode == 'Void' && step === 'Start') || (mode == 'Process' && step === 'Tender')) && (
                // Pop the drawer to deposit the cash and make change
                <TenderCashPayment
                    OnContinue={() => dispatch(setProcessStep('Finish'))}
                    onCancel={() => dispatch(reset())}
                />
            )}
            {step === 'Finish' && (
                // Submit the payment for processing
                <ProcessPayment onComplete={() => dispatch(reset())} onCancel={() => dispatch(reset())}>
                    <CashPaymentDescription />
                </ProcessPayment>
            )}
        </Modal>
    );
};
