import { emptySplitApi as api } from './RtkQueryClient-Empty';
export const addTagTypes = ['Baskets', 'CloudEvent', 'Orders', 'Users', 'Workflows', 'Workstations'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: build => ({
            getBasket: build.query<GetBasketApiResponse, GetBasketApiArg>({
                query: queryArg => ({ url: `/api/v1/baskets/${queryArg.basketId}` }),
                providesTags: ['Baskets'],
            }),
            getBasketSummaries: build.query<GetBasketSummariesApiResponse, GetBasketSummariesApiArg>({
                query: queryArg => ({
                    url: `/api/v1/baskets`,
                    params: {
                        Term: queryArg.term,
                        BasketIds: queryArg.basketIds,
                        BasketType: queryArg.basketType,
                        OwnerId: queryArg.ownerId,
                        SellingLocationId: queryArg.sellingLocationId,
                        SourceSystemId: queryArg.sourceSystemId,
                        Statuses: queryArg.statuses,
                        PaymentStates: queryArg.paymentStates,
                        SourceSystemIdType: queryArg.sourceSystemIdType,
                        PageNumber: queryArg.pageNumber,
                        PageSize: queryArg.pageSize,
                        SortField: queryArg.sortField,
                        SortDirection: queryArg.sortDirection,
                    },
                }),
                providesTags: ['Baskets'],
            }),
            getOrderTenderSummary: build.query<GetOrderTenderSummaryApiResponse, GetOrderTenderSummaryApiArg>({
                query: queryArg => ({ url: `/api/v1/baskets/${queryArg.basketId}/order-tender-summary` }),
                providesTags: ['Baskets'],
            }),
            getBasketPayments: build.query<GetBasketPaymentsApiResponse, GetBasketPaymentsApiArg>({
                query: queryArg => ({
                    url: `/api/v1/baskets/${queryArg.basketId}/payments`,
                    params: {
                        PaymentStatuses: queryArg.paymentStatuses,
                        CheckNumbers: queryArg.checkNumbers,
                        PaymentTypes: queryArg.paymentTypes,
                        PageNumber: queryArg.pageNumber,
                        PageSize: queryArg.pageSize,
                        SortField: queryArg.sortField,
                        SortDirection: queryArg.sortDirection,
                    },
                }),
                providesTags: ['Baskets'],
            }),
            addBasketPayment: build.mutation<AddBasketPaymentApiResponse, AddBasketPaymentApiArg>({
                query: queryArg => ({
                    url: `/api/v1/baskets/${queryArg.basketId}/payments`,
                    method: 'POST',
                    body: queryArg.body,
                }),
                invalidatesTags: ['Baskets'],
            }),
            handleBasketPaymentAction: build.mutation<
                HandleBasketPaymentActionApiResponse,
                HandleBasketPaymentActionApiArg
            >({
                query: queryArg => ({
                    url: `/api/v1/baskets/${queryArg.basketId}/payments/${queryArg.paymentId}`,
                    method: 'POST',
                    body: queryArg.body,
                }),
                invalidatesTags: ['Baskets'],
            }),
            deletePayment: build.mutation<DeletePaymentApiResponse, DeletePaymentApiArg>({
                query: queryArg => ({
                    url: `/api/v1/baskets/${queryArg.basketId}/payments/${queryArg.paymentId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['Baskets'],
            }),
            updatePayment: build.mutation<UpdatePaymentApiResponse, UpdatePaymentApiArg>({
                query: queryArg => ({
                    url: `/api/v1/baskets/${queryArg.basketId}/payments/${queryArg.paymentId}`,
                    method: 'PATCH',
                    body: queryArg.body,
                }),
                invalidatesTags: ['Baskets'],
            }),
            getBasketWorkflowProgression: build.query<
                GetBasketWorkflowProgressionApiResponse,
                GetBasketWorkflowProgressionApiArg
            >({
                query: queryArg => ({ url: `/api/v1/baskets/${queryArg.basketId}/workflow` }),
                providesTags: ['Baskets'],
            }),
            sendUpdatedWorkflowProgression: build.mutation<
                SendUpdatedWorkflowProgressionApiResponse,
                SendUpdatedWorkflowProgressionApiArg
            >({
                query: queryArg => ({
                    url: `/api/v1/baskets/${queryArg.basketId}/workflow`,
                    method: 'POST',
                    body: queryArg.workflowProgression,
                }),
                invalidatesTags: ['Baskets'],
            }),
            options: build.mutation<OptionsApiResponse, OptionsApiArg>({
                query: () => ({ url: `/events`, method: 'OPTIONS' }),
                invalidatesTags: ['CloudEvent'],
            }),
            receiveCloudEvent: build.mutation<ReceiveCloudEventApiResponse, ReceiveCloudEventApiArg>({
                query: queryArg => ({ url: `/events`, method: 'POST', body: queryArg.body }),
                invalidatesTags: ['CloudEvent'],
            }),
            createOrders: build.mutation<CreateOrdersApiResponse, CreateOrdersApiArg>({
                query: queryArg => ({ url: `/api/v1/orders`, method: 'POST', body: queryArg.orderTenderRequestDto }),
                invalidatesTags: ['Orders'],
            }),
            getCheckoutAccess: build.query<GetCheckoutAccessApiResponse, GetCheckoutAccessApiArg>({
                query: queryArg => ({
                    url: `/api/v1/users/checkout-access`,
                    params: { storeLocationNumber: queryArg.storeLocationNumber },
                }),
                providesTags: ['Users'],
            }),
            getDocumentWorkflowStatus: build.query<
                GetDocumentWorkflowStatusApiResponse,
                GetDocumentWorkflowStatusApiArg
            >({
                query: queryArg => ({ url: `/api/v1/workflows/${queryArg.cartId}` }),
                providesTags: ['Workflows'],
            }),
            getWorkstationByNameAndLocation: build.query<
                GetWorkstationByNameAndLocationApiResponse,
                GetWorkstationByNameAndLocationApiArg
            >({
                query: queryArg => ({ url: `/api/v1/workstations/${queryArg.name}/${queryArg.storeLocationId}` }),
                providesTags: ['Workstations'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as CheckoutApi };
export type GetBasketApiResponse = /** status 200 Details of the requested basket. */ BasketDto;
export type GetBasketApiArg = {
    /** The ID of the basket to look for. */
    basketId: string;
};
export type GetBasketSummariesApiResponse =
    /** status 200 A paginated list of basket summaries. */ BasketSummaryDtoPaginatedListDto;
export type GetBasketSummariesApiArg = {
    term?: string;
    basketIds?: string[];
    basketType?: BasketType;
    ownerId?: string;
    sellingLocationId?: string;
    sourceSystemId?: string;
    statuses?: BasketStatus[];
    paymentStates?: BasketPaymentState[];
    sourceSystemIdType?: SourceSystemIdType;
    pageNumber?: number;
    pageSize?: number;
    sortField?: string;
    sortDirection?: SearchSortDirection;
};
export type GetOrderTenderSummaryApiResponse =
    /** status 200 An order tender summary of the basket requested. */ OrderTenderSummaryDto;
export type GetOrderTenderSummaryApiArg = {
    /** The ID of the basket to the order tender summary from. */
    basketId: string;
};
export type GetBasketPaymentsApiResponse = /** status 200 The filtered payments. */ PaymentDtoPaginatedListDto;
export type GetBasketPaymentsApiArg = {
    /** The ID of the basket to obtain the payments from. */
    basketId: string;
    paymentStatuses?: PaymentStatus[];
    checkNumbers?: string[];
    paymentTypes?: PaymentType[];
    pageNumber?: number;
    pageSize?: number;
    sortField?: string;
    sortDirection?: SearchSortDirection;
};
export type AddBasketPaymentApiResponse = /** status 200 The created payment details. */
    | PaymentDto
    | AccountsPayablePaymentDto
    | AchPaymentDto
    | BankDraftPaymentDto
    | CashPaymentDto
    | CheckPaymentDto
    | DebitPaymentDto
    | CertifiedFundsPaymentDto;
export type AddBasketPaymentApiArg = {
    /** The ID of the basket to add the payment too. */
    basketId: string;
    /** Request data containing payment details. */
    body:
        | CreateBasketPaymentRequestDto
        | CreateBasketCertifiedFundsPaymentRequestDto
        | CreateBasketCashPaymentRequestDto
        | CreateBasketCheckPaymentRequestDto
        | CreateBasketDebitPaymentRequestDto;
};
export type HandleBasketPaymentActionApiResponse = /** status 200 The payment after processing */
    | PaymentDto
    | AccountsPayablePaymentDto
    | AchPaymentDto
    | BankDraftPaymentDto
    | CashPaymentDto
    | CheckPaymentDto
    | DebitPaymentDto
    | CertifiedFundsPaymentDto;
export type HandleBasketPaymentActionApiArg = {
    /** The ID of the basket where the payment is. */
    basketId: string;
    /** The ID of the payment to take action on. */
    paymentId: string;
    /** Request data containing payment details. */
    body:
        | BasketPaymentActionRequestDto
        | ProcessBasketPaymentActionRequestDto
        | VoidBasketPaymentActionRequestDto
        | RefundBasketPaymentActionRequestDto;
};
export type DeletePaymentApiResponse = /** status 204 No Content */ void;
export type DeletePaymentApiArg = {
    /** Basket ID */
    basketId: string;
    /** Payment ID */
    paymentId: string;
};
export type UpdatePaymentApiResponse = /** status 200 OK */
    | PaymentDto
    | AccountsPayablePaymentDto
    | AchPaymentDto
    | BankDraftPaymentDto
    | CashPaymentDto
    | CheckPaymentDto
    | DebitPaymentDto
    | CertifiedFundsPaymentDto;
export type UpdatePaymentApiArg = {
    /** The Payment ID */
    paymentId: string;
    basketId: string;
    /** The updated Payment */
    body: UpdatePaymentRequest | UpdateCheckPaymentRequest;
};
export type GetBasketWorkflowProgressionApiResponse =
    /** status 200 The basket's workflow progression */ WorkflowProgression;
export type GetBasketWorkflowProgressionApiArg = {
    /** Basket id */
    basketId: string;
};
export type SendUpdatedWorkflowProgressionApiResponse = /** status 204 No Content */ void;
export type SendUpdatedWorkflowProgressionApiArg = {
    basketId: string;
    workflowProgression: WorkflowProgression;
};
export type OptionsApiResponse = unknown;
export type OptionsApiArg = void;
export type ReceiveCloudEventApiResponse = unknown;
export type ReceiveCloudEventApiArg = {
    body: any;
};
export type CreateOrdersApiResponse =
    /** status 200 The collection of orders that were created from the basket. */ OrderDto[];
export type CreateOrdersApiArg = {
    /** Request data containing the basked ID that needs to be converted to orders. */
    orderTenderRequestDto: OrderTenderRequestDto;
};
export type GetCheckoutAccessApiResponse =
    /** status 200 The user's store access result for the given store */ StoreAccessResult;
export type GetCheckoutAccessApiArg = {
    /** The store location number to check CMXDB access to */
    storeLocationNumber?: number;
};
export type GetDocumentWorkflowStatusApiResponse = /** status 200 OK */ DocGenerationDto;
export type GetDocumentWorkflowStatusApiArg = {
    cartId: string;
};
export type GetWorkstationByNameAndLocationApiResponse =
    /** status 200 Details of the requested workstation. */ WorkstationSummaryDto;
export type GetWorkstationByNameAndLocationApiArg = {
    /** The name of the workstation to look for. */
    name: string;
    /** The ID of the store location the workstation belongs to. */
    storeLocationId: string;
};
export type BasketStatus = 'ACTIVE' | 'ABANDONED' | 'COMPLETED';
export type LineItemType =
    | 'ACCESSORY_ITEM'
    | 'APPRAISAL_ITEM'
    | 'AUCTION_ITEM'
    | 'CAF_ITEM'
    | 'OTHER_ITEM'
    | 'SERVICE_ITEM'
    | 'VEHICLE_SALE_ITEM'
    | 'GAP_SERVICE_ITEM'
    | 'ESP_SERVICE_ITEM';
export type PartyRole =
    | 'BUYER'
    | 'BUYER_REP'
    | 'CO_BUYER'
    | 'SELLER'
    | 'SELLER_REP'
    | 'TITLE_HOLDER'
    | 'LIEN_HOLDER'
    | 'ACTOR'
    | 'OWNER';
export type LineItemParticipantDto = {
    id?: string | null;
    name?: string | null;
    roles?: PartyRole[] | null;
};
export type BasketLineItemDto = {
    name?: string | null;
    lineItemType?: LineItemType;
    unitPrice?: number;
    quantity?: number;
    equity?: number | null;
    children?: BasketLineItemDto[] | null;
    participants?: LineItemParticipantDto[] | null;
};
export type PaymentDirection = 'INBOUND' | 'OUTBOUND';
export type PaymentStatus =
    | 'NEW'
    | 'PENDING'
    | 'AUTHORIZED'
    | 'PAID'
    | 'REFUNDED'
    | 'VOIDED'
    | 'CANCELLED'
    | 'DECLINED'
    | 'ERROR'
    | 'PROCESSING'
    | 'SUBMITTED';
export type PaymentDtoBase = {
    paymentType: string;
    externalId?: string | null;
    owningEntityId?: string | null;
    amount?: number;
    direction?: PaymentDirection;
    lineItemId?: string | null;
    lineOfBusinessProvided?: boolean;
    status?: PaymentStatus;
    tenantId?: string | null;
    id?: string | null;
};
export type AcceleratedPayoffProvider = 'UNKNOWN' | 'NONE' | 'DEALERTRACK';
export type PayoffStatus =
    | 'PENDING_ELECTRONIC_FUNDS_TRANSFER'
    | 'NON_SUFFICIENT_FUNDS'
    | 'AWAITING_LENDER_RELEASE'
    | 'PENDING_FUNDS_CLEARNING'
    | 'PAYOFF_SUBMITTED'
    | 'CANCELLED'
    | 'REFUNDED'
    | 'SUBMISSION_ERROR'
    | 'CANCEL_INITIATED'
    | 'PENDING_APPROVAL'
    | 'PAYMENT_ERROR';
export type TitleStatus =
    | 'TITLE_SENT'
    | 'DELIVERED'
    | 'SENT_TO_DMV'
    | 'TITLE_PROCESSING'
    | 'TITLE_EXCEPTION'
    | 'DMV_PROCESSED';
export type PayoffQuoteDetailsDto = {
    acceleratedPayoffProvider?: AcceleratedPayoffProvider;
    internalPayoffQuoteId?: string | null;
    externalPayoffQuoteId?: string | null;
    payoffSubmissionId?: string | null;
    payoffStatus?: PayoffStatus;
    titleStatus?: TitleStatus;
};
export type AccountsPayablePaymentDto = {
    paymentType: 'ACCOUNTS_PAYABLE';
} & PaymentDtoBase & {
        payoffQuoteDetails?: PayoffQuoteDetailsDto;
    };
export type AchPaymentDto = {
    paymentType: 'ACH';
} & PaymentDtoBase & {
        payoffQuoteDetails?: PayoffQuoteDetailsDto;
    };
export type BankDraftPaymentDto = {
    paymentType: 'BANK_DRAFT';
} & PaymentDtoBase & {
        checkNumber?: string | null;
    };
export type CashPaymentDto = {
    paymentType: 'CASH';
} & PaymentDtoBase & {
        cashCollected?: number | null;
    };
export type CheckPaymentDto = {
    paymentType: 'CHECK';
} & PaymentDtoBase & {
        checkNumber?: string | null;
    };
export type DebitPaymentDto = {
    paymentType: 'DEBIT';
} & PaymentDtoBase & {
        cardSuffix?: string | null;
        expirationDate?: string | null;
    };
export type CertifiedFundsPaymentDto = {
    paymentType: 'CERTIFIED_FUNDS';
} & PaymentDtoBase & {
        checkNumber?: string | null;
        bankName?: string | null;
    };
export type PaymentDto =
    | AccountsPayablePaymentDto
    | AchPaymentDto
    | BankDraftPaymentDto
    | CashPaymentDto
    | CheckPaymentDto
    | DebitPaymentDto
    | CertifiedFundsPaymentDto;
export type BasketDto = {
    cartId?: string | null;
    basketOwnerName?: string | null;
    basketStatus?: BasketStatus;
    customerProcessedPaymentAmount?: number;
    lineItems?: BasketLineItemDto[] | null;
    payments?:
        | (
              | PaymentDto
              | AccountsPayablePaymentDto
              | AchPaymentDto
              | BankDraftPaymentDto
              | CashPaymentDto
              | CheckPaymentDto
              | DebitPaymentDto
              | CertifiedFundsPaymentDto
          )[]
        | null;
};
export type ProblemDetails = {
    type?: string | null;
    title?: string | null;
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
    [key: string]: any;
};
export type BasketType = 'STANDARD' | 'SAVE_FOR_LATER';
export type BasketPaymentState = 'NEW' | 'PARTIALLY_PAID' | 'PAID' | 'ERROR';
export type DecoratorType =
    | 'VIN'
    | 'ORIGINAL_PAYOFF_AMOUNT'
    | 'PATH_TITLE_ID'
    | 'VEHICLE_YEAR'
    | 'VEHICLE_MAKE'
    | 'VEHICLE_MODEL'
    | 'VEHICLE_TRIM'
    | 'VEHICLE_COLOR'
    | 'VEHICLE_MILEAGE'
    | 'APPRAISAL_EQUITY';
export type DecoratorSummaryDto = {
    type?: DecoratorType;
    values?: any[] | null;
};
export type BasketSummaryDto = {
    basketType?: BasketType;
    currencyCode?: string | null;
    sellingLocationId?: string | null;
    status?: BasketStatus;
    statusDate?: string | null;
    paymentState?: BasketPaymentState;
    inboundPaymentDue?: number;
    outboundPaymentDue?: number;
    customerName?: string | null;
    customerPhoneNumber?: string | null;
    equity?: number | null;
    decoratorsSummary?: DecoratorSummaryDto[] | null;
    createdOnUtc?: string;
    id?: string | null;
};
export type SearchSortDirection = 'ASC' | 'DESC';
export type BasketSummaryDtoPaginatedListDto = {
    results?: BasketSummaryDto[] | null;
    pageSize?: number;
    pageNumber?: number;
    totalPages?: number;
    totalCount?: number;
    sortField?: string | null;
    sortDirection?: SearchSortDirection;
    previousPage?: boolean;
    nextPage?: boolean;
};
export type SourceSystemIdType = 'NONE' | 'CART';
export type OrderTenderSummaryDto = {
    vehicleName?: string | null;
    vehicleOfferValue?: number | null;
    gapCancellationValue?: number;
    espCancellationValue?: number;
    payoffToLienholder?: number;
    lienholderName?: string | null;
    processedPaymentsTotal?: number;
    equityTotal?: number | null;
};
export type PaymentDtoPaginatedListDto = {
    results?:
        | (
              | PaymentDto
              | AccountsPayablePaymentDto
              | AchPaymentDto
              | BankDraftPaymentDto
              | CashPaymentDto
              | CheckPaymentDto
              | DebitPaymentDto
              | CertifiedFundsPaymentDto
          )[]
        | null;
    pageSize?: number;
    pageNumber?: number;
    totalPages?: number;
    totalCount?: number;
    sortField?: string | null;
    sortDirection?: SearchSortDirection;
    previousPage?: boolean;
    nextPage?: boolean;
};
export type PaymentType = 'ACCOUNTS_PAYABLE' | 'ACH' | 'BANK_DRAFT' | 'CASH' | 'CHECK' | 'DEBIT' | 'CERTIFIED_FUNDS';
export type ValidationProblemDetails = {
    errors?: {
        [key: string]: string[];
    } | null;
    type?: string | null;
    title?: string | null;
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
    [key: string]: any;
};
export type PartyRoleRequestDto = {
    partyId?: string | null;
    relationship?: PartyRole;
};
export type CreateBasketPaymentRequestDtoBase = {
    paymentType: string;
    amount?: number | null;
    direction?: PaymentDirection;
    lineOfBusinessProvided?: boolean | null;
    partyRole?: PartyRoleRequestDto;
};
export type CreateBasketCertifiedFundsPaymentRequestDto = {
    paymentType: 'CERTIFIED_FUNDS';
} & CreateBasketPaymentRequestDtoBase & {
        checkNumber?: string | null;
        bankName?: string | null;
    };
export type CreateBasketCashPaymentRequestDto = {
    paymentType: 'CASH';
} & CreateBasketPaymentRequestDtoBase & {
        cashCollected?: number | null;
    };
export type CreateBasketCheckPaymentRequestDto = {
    paymentType: 'CHECK';
} & CreateBasketPaymentRequestDtoBase & {
        checkNumber?: string | null;
    };
export type CreateBasketDebitPaymentRequestDto = {
    paymentType: 'DEBIT';
} & CreateBasketPaymentRequestDtoBase &
    object;
export type CreateBasketPaymentRequestDto =
    | CreateBasketCertifiedFundsPaymentRequestDto
    | CreateBasketCashPaymentRequestDto
    | CreateBasketCheckPaymentRequestDto
    | CreateBasketDebitPaymentRequestDto;
export type BasketPaymentActionRequestDtoBase = {
    paymentAction: string;
    storeLocationId?: string | null;
    workstationName?: string | null;
    equipmentIdentifier?: string | null;
    startTime?: string;
    endTime?: string;
};
export type ProcessBasketPaymentDetailsDto = object;
export type ProcessBasketCashPaymentDetailsDto = ProcessBasketPaymentDetailsDto & {
    cashCollected?: number;
};
export type ProcessBasketCheckPaymentDetailsDto = ProcessBasketPaymentDetailsDto & {
    checkNumber?: string | null;
};
export type CardDetailsCollectionMethod = 'SWIPED' | 'MANUALLY_KEYED';
export type DebitCardPresentPaymentDetailsDto = {
    encryptedPin?: string | null;
    pinKey?: string | null;
    accountNumberSuffix?: string | null;
    expirationDate?: string | null;
    bin?: string | null;
    encryptedData?: string | null;
    keyData?: string | null;
    terminalId?: string | null;
    cardDetailsCollectionMethod?: CardDetailsCollectionMethod;
};
export type ProcessBasketDebitPaymentDetailsDto = ProcessBasketPaymentDetailsDto & {
    debitCardPresentPaymentDetails?: DebitCardPresentPaymentDetailsDto;
};
export type ProcessBasketPaymentActionRequestDto = {
    paymentAction: 'PROCESS';
} & BasketPaymentActionRequestDtoBase & {
        paymentDetails?:
            | (
                  | ProcessBasketPaymentDetailsDto
                  | ProcessBasketCashPaymentDetailsDto
                  | ProcessBasketCheckPaymentDetailsDto
                  | ProcessBasketDebitPaymentDetailsDto
              )
            | null;
    };
export type VoidBasketPaymentActionRequestDto = {
    paymentAction: 'VOID';
} & BasketPaymentActionRequestDtoBase &
    object;
export type RefundBasketPaymentActionRequestDto = {
    paymentAction: 'REFUND';
} & BasketPaymentActionRequestDtoBase &
    object;
export type BasketPaymentActionRequestDto =
    | ProcessBasketPaymentActionRequestDto
    | VoidBasketPaymentActionRequestDto
    | RefundBasketPaymentActionRequestDto;
export type UpdatePaymentRequestBase = {
    paymentType: PaymentType;
    amount?: number | null;
};
export type UpdateCheckPaymentRequest = {
    paymentType: 'CHECK';
} & UpdatePaymentRequestBase & {
        checkNumber: string | null;
    };
export type UpdatePaymentRequest = UpdateCheckPaymentRequest;
export type WorkflowProgressionStepStatus = 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETE' | 'SKIPPED';
export type WorkflowProgressionStep = {
    stepName?: string | null;
    status?: WorkflowProgressionStepStatus;
    isEnabled?: boolean;
    remoteUrl?: string | null;
};
export type WorkflowProgression = {
    contractSessionId?: string | null;
    steps?: WorkflowProgressionStep[] | null;
};
export type OrderType = 'SALES' | 'PURCHASE' | 'RETURN' | 'TRANSFER';
export type OrderStatus =
    | 'NEW'
    | 'PENDING'
    | 'PROCESSING'
    | 'CANCELLED'
    | 'PARTIALLY_COMPLETED'
    | 'COMPLETED'
    | 'ERROR';
export type LineItemStatus = 'NEW' | 'PROCESSING' | 'CANCELLED' | 'COMPLETED' | 'ERROR';
export type LineItemDecorationDtoBase = {
    lineItemDecorationType: string;
    lineItemId?: string | null;
    decorationTypeId?: number;
    decorationTypeName?: string | null;
    id?: string | null;
};
export type ShortTextLineItemDecorationDto = {
    lineItemDecorationType: 'SHORT_TEXT_VALUE';
} & LineItemDecorationDtoBase & {
        shortTextValue?: string | null;
    };
export type LongTextLineItemDecorationDto = {
    lineItemDecorationType: 'LONG_TEXT_VALUE';
} & LineItemDecorationDtoBase & {
        longTextValue?: string | null;
    };
export type BooleanLineItemDecorationDto = {
    lineItemDecorationType: 'BOOLEAN_VALUE';
} & LineItemDecorationDtoBase & {
        booleanValue?: boolean;
    };
export type IntegerLineItemDecorationDto = {
    lineItemDecorationType: 'INTEGER_VALUE';
} & LineItemDecorationDtoBase & {
        integerValue?: number;
    };
export type DecimalLineItemDecorationDto = {
    lineItemDecorationType: 'DECIMAL_VALUE';
} & LineItemDecorationDtoBase & {
        decimalValue?: number;
    };
export type DateLineItemDecorationDto = {
    lineItemDecorationType: 'DATE_VALUE';
} & LineItemDecorationDtoBase & {
        dateValue?: string;
    };
export type LineItemDecorationDto =
    | ShortTextLineItemDecorationDto
    | LongTextLineItemDecorationDto
    | BooleanLineItemDecorationDto
    | IntegerLineItemDecorationDto
    | DecimalLineItemDecorationDto
    | DateLineItemDecorationDto;
export type PaymentLineItemDto = {
    amount?: number;
    lineItemId?: string | null;
    paymentId?: string | null;
    id?: string | null;
};
export type LineItemDto = {
    externalId?: string | null;
    itemId?: string | null;
    lineItemType?: LineItemType;
    name?: string | null;
    status?: LineItemStatus;
    statusDate?: string | null;
    quantity?: number;
    unitPrice?: number;
    orderId?: string | null;
    parentLineItemId?: string | null;
    decorations?:
        | (
              | LineItemDecorationDto
              | ShortTextLineItemDecorationDto
              | LongTextLineItemDecorationDto
              | BooleanLineItemDecorationDto
              | IntegerLineItemDecorationDto
              | DecimalLineItemDecorationDto
              | DateLineItemDecorationDto
          )[]
        | null;
    paymentLineItems?: PaymentLineItemDto[] | null;
    id?: string | null;
};
export type PartyIdentifierType = 'NONE' | 'CIAM_ID' | 'LIENHOLDER_ID' | 'SF_DEALER_ID' | 'STORE_CUST';
export type PartyDetailsDtoBase = {
    partyType: string;
    owningEntityId?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    id?: string | null;
};
export type IndividualDetailsDto = {
    partyType: 'INDIVIDUAL';
} & PartyDetailsDtoBase & {
        firstName?: string | null;
        middleName?: string | null;
        lastName?: string | null;
        fullName?: string | null;
        legalName?: string | null;
    };
export type OrganizationType = 'INTERNAL' | 'EXTERNAL';
export type OrganizationDetailsDto = {
    partyType: 'ORGANIZATION';
} & PartyDetailsDtoBase & {
        dunsNumber?: string | null;
        organizationName?: string | null;
        organizationType?: OrganizationType;
    };
export type PartyDetailsDto = IndividualDetailsDto | OrganizationDetailsDto;
export type PartyAddressDto = {
    orderPartyId?: string | null;
    addressLine1?: string | null;
    addressLine2?: string | null;
    city?: string | null;
    county?: string | null;
    postalCode?: string | null;
    stateCode?: string | null;
    id?: string | null;
};
export type PartyRoleReferenceType =
    | 'BASKET_ID'
    | 'ORDER_ID'
    | 'LINE_ITEM_ID'
    | 'PAYMENT_ID'
    | 'LINE_ITEM_PAYMENT_REQUIREMENT_ID';
export type PartyRoleReferenceDto = {
    orderPartyId?: string | null;
    rel?: PartyRole;
    refType?: PartyRoleReferenceType;
    refValue?: string | null;
};
export type PartyDto = {
    orderId?: string | null;
    partyId?: string | null;
    partyIdType?: PartyIdentifierType;
    partyDetails?: (PartyDetailsDto | IndividualDetailsDto | OrganizationDetailsDto) | null;
    addresses?: PartyAddressDto[] | null;
    roles?: PartyRoleReferenceDto[] | null;
    id?: string | null;
};
export type OrderDto = {
    identifier?: string | null;
    basketId?: string | null;
    currencyCode?: string | null;
    externalOrderNumber?: string | null;
    orderCreated?: string;
    orderCreatedTransactionId?: string | null;
    orderType?: OrderType;
    sellingLocationId?: string | null;
    sourceSystem?: string | null;
    status?: OrderStatus;
    statusDate?: string | null;
    tenantId?: string | null;
    inboundPaymentSubtotal?: number;
    outboundPaymentSubtotal?: number;
    total?: number;
    lineItems?: LineItemDto[] | null;
    payments?:
        | (
              | PaymentDto
              | AccountsPayablePaymentDto
              | AchPaymentDto
              | BankDraftPaymentDto
              | CashPaymentDto
              | CheckPaymentDto
              | DebitPaymentDto
              | CertifiedFundsPaymentDto
          )[]
        | null;
    parties?: PartyDto[] | null;
    id?: string | null;
};
export type OrderTenderRequestDto = {
    basketId: string;
};
export type StoreAccessResult = {
    userExists?: boolean;
    isClockedIn?: boolean;
};
export type DocGenerationDto = {
    documentsGenerationId?: string | null;
    documentsGenerationStatus?: string | null;
};
export type WorkstationType = 'STATIONARY' | 'MOBILE' | 'SAFEROOM';
export type WorkstationSummaryDto = {
    classicSystemsId?: number | null;
    isDrawerAssigned?: boolean | null;
    workstationType?: WorkstationType;
    storeLocationId?: string | null;
    name?: string | null;
    isDeleted?: boolean | null;
    deletedOnUtc?: string | null;
    deletedByUserId?: string | null;
    deletedByUserName?: string | null;
    createdByUserId?: string | null;
    createdByUserName?: string | null;
    createdOnUtc?: string | null;
    updatedByUserId?: string | null;
    updatedByUserName?: string | null;
    updatedUtc?: string | null;
    id?: string | null;
};
export const {
    useGetBasketQuery,
    useGetBasketSummariesQuery,
    useGetOrderTenderSummaryQuery,
    useGetBasketPaymentsQuery,
    useAddBasketPaymentMutation,
    useHandleBasketPaymentActionMutation,
    useDeletePaymentMutation,
    useUpdatePaymentMutation,
    useGetBasketWorkflowProgressionQuery,
    useSendUpdatedWorkflowProgressionMutation,
    useOptionsMutation,
    useReceiveCloudEventMutation,
    useCreateOrdersMutation,
    useGetCheckoutAccessQuery,
    useGetDocumentWorkflowStatusQuery,
    useGetWorkstationByNameAndLocationQuery,
} = injectedRtkApi;
