import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { CashPaymentDto, PaymentDto } from '@src/api/CheckoutApi';
import { formatISO } from 'date-fns';
import { TransactionResult } from '@src/api/CardFlowApi';

type PaymentMode = 'Process' | 'Void'
type PaymentStep = 'Start' | 'Tender' | 'CheckScan' | 'Finish';
export type DebitPaymentDetails = TransactionResult;
export type CashPaymentDetails = { cashCollected: number };
export type CheckPaymentDetails = { checkNumber: string };
export type CertifiedFundsPaymentDetails = { checkNumber: string, checkAmount: number };
export type PaymentDetails = DebitPaymentDetails | CashPaymentDetails | CheckPaymentDetails | CertifiedFundsPaymentDetails | undefined;
type SetPaymentState = { payment: PaymentDto, mode: PaymentMode };

export interface PaymentState {
    payment?: PaymentDto;
    startTime?: string;
    paymentDetails: PaymentDetails;
    mode?: PaymentMode;
    step?: PaymentStep;
}

const initialState: PaymentState = {
    payment: undefined,
    startTime: undefined,
    paymentDetails: undefined,
    mode: undefined,
    step: undefined,
};

export const PaymentSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setPayment: (state, action: PayloadAction<SetPaymentState | undefined>) => {
            state.payment = action.payload.payment;
            state.startTime = formatISO(Date.now());
            state.mode = action.payload.mode;
            state.step = 'Start';
        },
        setPaymentDetails: (state, action: PayloadAction<PaymentDetails | undefined>) => {
            state.paymentDetails = action.payload;
        },
        setProcessStep: (state, action: PayloadAction<PaymentStep>) => {
            state.step = action.payload;
        },
        reset: (state, action: Action) => initialState,
    },
});

export const { setPayment, setPaymentDetails, setProcessStep, reset } = PaymentSlice.actions;

export const selectPayment = (state: RootState) => state.payment;

export default PaymentSlice.reducer;
